import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import icon from "assets/images/main/logo.svg";
import cn from "classnames";
import { useMediaQuery } from "react-responsive";
import { FaBars, FaTimes } from "react-icons/fa";
import { BUY_NOW_URL } from "utils/config/app.config";
import { getPresaleStats } from "services/api";
import { POLLING_INTERVAL } from "utils/constants";
import ROCKET from 'assets/rocket.svg';
import wrapped_present from 'assets/wrapped-present.svg';
import star_struck from 'assets/star-struck.svg';
import sunglasses_emoji from 'assets/sunglasses_emoji.svg'

const Header = () => {
  const isTablet = useMediaQuery({ maxWidth: 1000 });
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [currentPhase, setCurrentPhase] = useState(0);
  const currentPath = location.pathname;

  const submenuRef = useRef(null);

  const getLinkClassName = (path) => {
    const baseClasses = "inline-block py-2 px-3 text-white ml-6 w-fit";
    const activeClasses = "nav-active";
    if ((location.hash === '' && currentPath === path)) {
      return `${baseClasses} ${activeClasses}`;
    } else if (location.hash.indexOf(path) > -1) {
      return `${baseClasses} ${activeClasses} nav`;
    } else {
      return baseClasses;
    }
  };

  const handleOutsideClick = (event) => {
    if (submenuRef.current && !submenuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  const updateData = async () => {
    try {
      const data = await getPresaleStats();
      console.log(data);

      setCurrentPhase(data.currentPhase);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    updateData().catch((err) => console.error(err));
    const interval = setInterval(() => {
      updateData().catch((err) => console.error(err));
    }, POLLING_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (showMenu) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showMenu]);

  // const showMenu = (e) => {
  //   let elm = document.getElementById("navSubMenu");
  //   if (elm) {
  //     elm.classList.toggle("active");
  //   }
  // }
  // const string = String.fromCodePoint(0x1F525)
  // const bannerInfo = [`Ozak AI Presale Phase ${currentPhase} is now LIVE <img src={ROCKET}/>`, "BUY $OZ to be part of biggest AI project of 2025 & earn rewards worth $1 Million", "Refer your friends to achieve higher ranks & at-least 10% bonus. Participate NOW!!"]

  return (
    <>
      <div className="w-full h-[125px] z-50 bg-[#000000] fixed top-0">
        <div class="p-1 font-[archivo] font-medium">
          <div class="flex overflow-hidden">
            <div className="text-nowrap text-[#ffffff] animate-infinite-scroll flex gap-40">

              {/* {[...bannerInfo, ...bannerInfo].map((text) => <p class="mt-2 text-[#ffffff]" dangerouslySetInnerHTML={{ __html: text }}></p>)} */}
              <div className="flex ">
                <p class="mt-2 ">Ozak AI Presale Phase {currentPhase} is now LIVE </p>
                <img src={ROCKET} width={20} className="mt-2 ml-1" />
              </div>
              <div className="flex">
                <p class="mt-2 ">BUY $OZ to be part of biggest AI project of 2025 & earn rewards worth $1 Million</p>
                <img src={wrapped_present} width={20} className="mt-1 ml-1" />
                <img src={star_struck} width={20} className="mt-1 ml-1" />
              </div>
              <div className="flex">
                <p class="mt-2 ">Refer your friends to achieve higher ranks & at-least 10% bonus. Participate NOW!!</p>
                <img src={sunglasses_emoji} width={20} className="mt-1 ml-1" />
              </div>
              <div className="flex">
                <p class="mt-2 ">Ozak AI Presale Phase {currentPhase} is now LIVE </p>
                <img src={ROCKET} width={20} className="mt-2 ml-1" />
              </div>
              <div className="flex">
                <p class="mt-2 ">BUY $OZ to be part of biggest AI project of 2025 & earn rewards worth $1 Million</p>
                <img src={wrapped_present} width={20} className="mt-1 ml-1" />
                <img src={star_struck} width={20} className="mt-1 ml-1" />
              </div>
              <div className="flex">
                <p class="mt-2">Refer your friends to achieve higher ranks & at-least 10% bonus. Participate NOW!!</p>
                <img src={sunglasses_emoji} width={20} className="mt-1 ml-1" />
              </div>
            </div>
          </div>

        </div>
        <div>
          <div className="flex justify-between items-center w-full h-full relative top-4">
            <div>
              <Link to="https://www.ozak.ai/">
                <img src={icon} className="ml-8 md:ml-16" alt="" />
              </Link>
            </div>
            <div>
              {!isTablet ? (
                <div className="flex justify-between items-center mr-16 relative navbar">
                  <Link to="/" className={getLinkClassName("/")}>
                    Home
                  </Link>
                  <Link to="/presale" className={getLinkClassName("/presale")}>
                    Presale
                    <sup>
                      <span className="text-[#38d974] font-medium text-[14px] font-bold">
                        &nbsp;&nbsp;Live
                      </span>
                    </sup>
                  </Link>
                  <Link
                    to="/predictive-ai"
                    className={getLinkClassName("/predictive-ai")}
                  >
                    Predictive AI
                  </Link>
                  <Link to="/depin" className={getLinkClassName("/depin")}>
                    DePIN
                  </Link>
                  <Link to="/personal" className={getLinkClassName("/personal")}>
                    Personal
                  </Link>
                  <Link to="/business" className={getLinkClassName("/business")}>
                    Business
                  </Link>
                  <Link to="/community" className={getLinkClassName("/community")} onClick={() => setShowMenu((prev) => !prev)}>
                    Community
                  </Link>
                  {showMenu && <ul ref={submenuRef} id="" className={`nav-sub-menu`}>
                    <li><a href="https://t.me/OzakAGI" target="_blank">Telegram</a></li>
                    <li><a href="https://linktr.ee/ozakagi" target="_blank">Linktree</a></li>
                    <li><a href="https://x.com/OzakAGI" target="_blank">Twitter X</a></li>
                    <li><a href="https://www.reddit.com/u/ozakAGI" target="_blank">Reddit</a></li>
                  </ul>}
                  <a href={BUY_NOW_URL} target="_blank"
                    className="inline-block py-1.5 px-4 bg-white text-[#000000] rounded-[32px] ml-6 w-fit cursor-pointer"
                  >
                    Buy Now
                  </a>
                </div>
              ) : (
                <div className="flex gap-6 items-center mr-8 md:mr-16">
                  <button
                    className="text-white"
                    onClick={() => setMenuOpen(!menuOpen)}
                  >
                    {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                  </button>
                </div>
              )}
              {isTablet && menuOpen && (
                <div className="absolute top-[65px] left-0 w-full bg-[#000000] bg-opacity-100 text-white flex flex-col py-4">
                  <Link
                    to="/"
                    className={getLinkClassName("/")}
                    onClick={() => setMenuOpen(false)}
                  >
                    Home
                  </Link>
                  <Link
                    to="/presale"
                    className={getLinkClassName("/presale")}
                    onClick={() => setMenuOpen(false)}
                  >
                    Presale
                    <sup>
                      <span className="text-[#38d974] font-medium text-[14px] font-bold">
                        &nbsp;&nbsp;Live
                      </span>
                    </sup>
                  </Link>
                  <Link
                    to="/predictive-ai"
                    className={getLinkClassName("/predictive-ai")}
                    onClick={() => setMenuOpen(false)}
                  >
                    Predictive AI
                  </Link>
                  <Link
                    to="/depin"
                    className={getLinkClassName("/depin")}
                    onClick={() => setMenuOpen(false)}
                  >
                    DePIN
                  </Link>
                  <Link
                    to="/personal"
                    className={getLinkClassName("/personal")}
                    onClick={() => setMenuOpen(false)}
                  >
                    Personal
                  </Link>
                  <Link
                    to="/business"
                    className={getLinkClassName("/business")}
                    onClick={() => setMenuOpen(false)}
                  >
                    Business
                  </Link>
                  <Link
                    to="/community"
                    className={getLinkClassName("/community")}
                    onClick={() => setShowMenu((prev) => !prev)}
                  >
                    Community
                  </Link>
                  {showMenu && <ul ref={submenuRef} id="" className={`nav-sub-menu-mobile`}>
                    <li><a href="https://t.me/OzakAGI" target="_blank">Telegram</a></li>
                    <li><a href="https://linktr.ee/ozakagi" target="_blank">Linktree</a></li>
                    <li><a href="https://x.com/OzakAGI" target="_blank">Twitter X</a></li>
                    <li><a href="https://www.reddit.com/u/ozakAGI" target="_blank">Reddit</a></li>
                  </ul>}
                </div>
              )}
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default Header;
