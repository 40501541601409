import icon from "assets/images/main/logo.svg";
import { useMediaQuery } from "react-responsive";

import Terms_of_Service_Ozak_AI from "assets/docs/Terms of Service Ozak AI.pdf";
import Cookie_Policy from "assets/docs/Cookie Policy Ozak AI.pdf";
import Privacy_Policy from "assets/docs/Privacy Policy Ozak AI.pdf";
import { BUY_NOW_URL } from "utils/config/app.config";
import Disclaimer from "assets/docs/Disclaimers Ozak AI.pdf";
import EmailSubscribe from '../../../components/EmailInput.tsx';


const Footer = () => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  return (
    <div className="w-full bg-[url('assets/images/main/footer-bg.svg')] bg-cover bg-bottom">
      <div className=" w-full pt-5 px-6 md:px-10 lg:px-28 flex justify-center">
        <div className="max-w-[1300px] w-full">
          <div className="flex items-center justify-between">
            <img src={icon} className="" alt="" />
            <a href={BUY_NOW_URL} target="_blank"
              className="inline-block py-1.5 px-4 bg-white text-[#000000] rounded-[32px] ml-6 w-fit cursor-pointer"
            >
              Buy Now
            </a>
          </div>
          <hr className="mt-8 mb-8 md:mb-16 border-[#FFFFFF29] h-[1px]" />
          <div className="flex flex-col gap-4 md:flex-row md:items-center justify-between text-white mb-12">
            <h3 className="font-[archivo] font-normal text-[24px] leading-[38.4px] text-left pl-3 max-w-[330px]">
              Ozak is predictive AI powered decentralized network for advance data analytics & interpretation.
            </h3>
            {/* <div className="flex flex-wrap justify-between mt-0 gap-4 md:gap-10 lg:gap-20">
              <EmailSubscribe />
            </div> */}
            <div className="flex flex-wrap justify-between gap-4 md:gap-10 lg:gap-20">
              <div className="">
                <p className="footer-link-header">
                  Sitemap
                </p>
                <a href="/presale">
                  <p className="footer-link-title">
                    Presale
                  </p>
                </a>
                <a href="/predictive-ai">
                  <p className="footer-link-title">
                    Predictive AI
                  </p>
                </a>
                <a href="/depin">
                  <p className="footer-link-title">
                    DePIN
                  </p>
                </a>
                <a href="/personal">
                  <p className="footer-link-title">
                    Personal
                  </p>
                </a>
                <a href="/business">
                  <p className="footer-link-title">
                    Business
                  </p>
                </a>
              </div>
              <div className="">
                <a href="#" target="_blank">
                  <p className="footer-link-header">
                    Legal
                  </p>
                </a>
                <a href={Terms_of_Service_Ozak_AI} target="_blank">
                  <p className="footer-link-title">
                    Terms of Use
                  </p>
                </a>
                <a href={Cookie_Policy} target="_blank">
                  <p className="footer-link-title">
                    Cookie Policy
                  </p>
                </a>
                <a href={Privacy_Policy} target="_blank">
                  <p className="footer-link-title">
                    Privacy Policy
                  </p>
                </a>
                <a href={Disclaimer} target="_blank">
                  <p className="footer-link-title">
                    Disclaimers
                  </p>
                </a>
              </div>
              <div>
                <p className="footer-link-header">
                  Socials
                </p>
                <a href="https://t.me/OzakAGI" target="_blank">
                  <p className="footer-link-title">
                    Telegram
                  </p>
                </a>
                <a href="https://linktr.ee/ozakagi" target="_blank">
                  <p className="footer-link-title">
                    Linktree
                  </p>
                </a>
                <a href="https://x.com/OzakAGI" target="_blank">
                  <p className="footer-link-title">
                    Twitter X
                  </p>
                </a>
                {/* <a href="">
                  <p className="footer-link-title">
                    Medium
                  </p>
                </a> */}
                <a href="https://www.reddit.com/user/ozakAGI/" target="_blank">
                  <p className="footer-link-title">
                    Reddit
                  </p>
                </a>
              </div>
            </div>
          </div>
          <hr className="mt-6 mb-8 border-[#FFFFFF29] h-[1px]" />
          <p className="text-[14px] font-[archivo] font-normal leading-[15.23px text-center text-white pb-8">
            Copyright © OZAK AI
          </p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
