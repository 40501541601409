import React, { Component } from 'react';
import Faq from 'react-faq-component';

const data = {
    title: "",
    rows: [
        {
            title: "What is Ozak AI?",
            content: "Ozak AI is a pioneering technology company specializing in predictive AI and advanced data analytics for financial markets.By leveraging machine learning algorithms and decentralized network technologies, Ozak AI provides real- time, accurate, and actionable insights to help individuals and businesses make informed decisions.The platform integrates the Ozak Stream Network(OSN), Decentralized Physical Infrastructure Networks(DePIN), Ozak Data Vaults, and customizable Prediction Agents to deliver a comprehensive data analytics solution."
        },
        {
            title: "Where can I get more technical details about Ozak AI?",
            content: "You can find detailed technical information about Ozak AI in our comprehensive whitepapers and technical papers available on our official website. Additionally, our GitBook provides in-depth documentation on the architecture, core components, algorithms, and technologies used by Ozak AI. For the latest updates and technical discussions, you can also follow our blog and join our community forums."
        },
        {
            title: "What sets Ozak AI apart from its competitors?",
            content: "Ozak AI stands out due to its unique combination of predictive AI and decentralized network infrastructure. Our platform offers several key advantages: <ul><li><b> - Real-Time Data Processing:</b> Ozak Stream Network (OSN) ensures low-latency data ingestion and processing. </li><li><b> - Decentralized Security:</b> Integration with DePIN provides enhanced data security and resilience through decentralized storage and processing.</li><li><b> - Customization:</b> Our Prediction Agents (PAs) are highly customizable, allowingusers to tailor the models to their specific needs. </li><li><b> - Scalability:</b> Ozak AI’s architecture is designed to scale dynamically, accommodating growing data volumes and user demands.</li></ul>"
        },
        {
            title: "What is OZ token?",
            content: "The OZ token is the native cryptocurrency of the Ozak AI platform. It serves multiple purposes, including powering transactions within the ecosystem, providing access to premium features, and enabling participation in governance decisions. OZ tokens can also be used to reward users for their contributions to the network."
        },
        {
            title: "How and where can I buy OZ tokens?",
            content: "OZ tokens can be purchased during our presale events and subsequent public sales. You can participate in these sales through our official website. Detailed instructions on how to purchase OZ tokens will be provided during the sale periods, including supported payment methods and platforms."
        },
        {
            title: "When and where will I receive OZ tokens?",
            content: "After successfully purchasing OZ tokens during presale, the amount of tokens will be shown in your account dashboard. You may login from your designated wallet to access all details on the dashboard. Once the presale is completed & TGE happens, users will be able to claim their OZ tokens from the dashboard and OZ tokens will be then transferable to user’s wallets.  OZ tokens shall be freely transferable once the presale closes & token generation event is completed. Stay tuned to official announcement channels for updates."
        },
        {
            title: "How many presale stages are there?",
            content: "Ozak AI’s token sale includes multiple presale stages to ensure fair distribution and to provide opportunities for early supporters to acquire OZ tokens at favorable rates. The specific number of presale stages and their respective terms, including token pricing and allocation, will be detailed on our official website."
        },
        {
            title: "How do I claim rewards?",
            content: "Rewards can be claimed through the user account dashboard. Users who participate in various activities, such as contributing data, staking tokens, or referring new users, will accumulate rewards in their account. These rewards can be claimed by navigating to the rewards section of the dashboard and following the provided instructions."
        },
        {
            title: "How are OZ tokens distributed?",
            content: "The distribution of OZ tokens is structured to ensure fair and equitable allocation. A portion of the tokens is reserved for presale and public sale events, while the remaining tokens are allocated for platform development, team incentives, community rewards, and strategic partnerships. Detailed information on the token distribution plan is available in our whitepaper and on our official website."
        },
        {
            title: "When and where will OZ be listed?",
            content: "OZ tokens will be listed on major cryptocurrency exchanges after the completion of the presale and public sale events. The exact listing dates and exchanges will be announced on our official website and through our social media channels. Stay tuned for updates to ensure you don't miss out on important announcements."
        },
        {
            title: "When can I have exclusive access to the OZAK platform?",
            content: "Exclusive access to the OZAK platform will be granted to early supporters and token holders shortly after the token sale events. Detailed timelines and access instructions will be provided on our official website and communicated directly to eligible participants via email. Early access will allow users to explore and utilize the platform's features ahead of the general public."
        },
        {
            title: "What is the official token contract address?",
            content: "There is NO token contract address currently. Once the TGE (Token Generation Event) is completed after closure of presale, official token contract address shall be communicated on all official channels & website in due time. Please beware of any frauds & scams."
        },
        {
            title: "I cannot see tokens in my wallet?",
            content: "You cannot see OZ tokens yet as TGE (Token Generation Event) has not happened yet. You can claim purchased & accumulated OZ tokens from your account’s dashboard only once the presale is closed & token generation event is completed. Official token contract address shall be communicated to users through official channels only."
        },
        {
            title: "Is this a scam or fraud?",
            content: "No, Ozak AI has completed all legalities & necessary compliance to conduct the business. More information regarding the same shall be available in the public domain in due time. Please be very careful & only refer to communication from official sources and do not fall prey to any scams who tend to imposter Ozak AI website & identity."
        },

    ]
}

export default class App extends Component {
    render() {
        return (
            <div>
                <Faq
                    data={data}
                    styles={{
                        bgColor: "#",
                        titleTextColor: "#FFFFFFB2",
                        rowTitleColor: "#FFFFFFB2",
                        rowTitleTextSize: '18px',
                        rowContentColor: "#FFFFFFB2",
                        rowContentTextSize: '16px',
                        rowContentPaddingTop: '10px',
                        rowContentPaddingBottom: '10px',
                        rowContentPaddingLeft: '10px',
                        rowContentPaddingRight: '50px',
                        arrowColor: "#ffffff29",
                    }}
                />
            </div>
        )
    }
}
