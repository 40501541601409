import ReactGA from 'react-ga4';
import MetaPixel from "../../utils/metaPixel";
import MetaTags from "components/MetaTags";
import image_7_1 from "assets/images/main/landing/7_1.png";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import BlogsHome from "components/BlogsHome";
import BlogOSN from "assets/images/main/landing/image_osn.png";
import { useEffect, useState } from 'react';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import { BASE_COLOR, HIGHLIGHT_COLOR } from 'utils/constants';



const CommunityPage = () => {
  // google analytic code
  ReactGA.send({
    hitType: "pageview",
    page: "/Community",
    title: "Community",
  });

  const isTablet = useMediaQuery({ maxWidth: 900 });
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false)
  const [blogs, setBlogs] = useState(["", "", "", "", "", ""]);
  const [highlightBlog, setHighlightBlog] = useState(null)
  const [hightlightDate, setHighlightDate] = useState()

  const getBlogsFromDatabase = async () => {
    try {


      // const blogs = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blog`)
      const blogs = await axios.get(`${process.env.REACT_APP_WORDPRESS_API_URL}/posts?_embed`)
      console.log(blogs.data);

      setBlogs(blogs.data)
      setLoaded(true)
      findHighlightBlog(blogs.data, true)
    } catch (error) {
      console.log(error);

    }
  }

  const findHighlightBlog = async (blogs, isLoaded) => {
    if (isLoaded) {

      const highlightBlog = blogs.filter((blog, index) => {
        if (blog.slug === process.env.REACT_APP_FEATURED_BLOG_SLUG) {
          return blog
        }
      })
      const _highlightBlog = highlightBlog[0]
      if (_highlightBlog) {

        console.log("Hightlight blog");
        const date = new Date(_highlightBlog.modified)
        setHighlightDate(date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' }))
        console.log(_highlightBlog);

        console.log("Image call response");
        console.log(_highlightBlog._embedded["wp:featuredmedia"][0].source_url)
        const highlightBlogImage = _highlightBlog._embedded["wp:featuredmedia"][0].source_url
        console.log(highlightBlogImage);
        setHighlightBlog({ ..._highlightBlog, featured_image: highlightBlogImage })

      }
    }
  }

  useEffect(() => {
    getBlogsFromDatabase()
  }, [])
  return (
    <div className="bg-[black] px-6 md:px-10 lg:px-28 flex justify-center">
      <MetaTags
        title="Blog - Ozak AI"
        description="Discover Ozak AI's blog featuring a collection of posts and research on predictive AI, decentralized technology, and advanced data analytics."
        name="OzakAI"
        image="https://www.ozak.ai/logo.png"
      />
      <div className="max-w-[1200px]">
        {highlightBlog && <div className="pt-8">
          {!isTablet ? (
            <a href={`/community/blog/${highlightBlog.id}/${highlightBlog.slug}`}>
              <div className="grid grid-cols-3 gap-0 rounded-xl overflow-hidden">
                <div>
                  <img src={highlightBlog.featured_image} alt="" className="w-full" />
                </div>
                <div className="col-span-2 bg-[#0E0E0E] p-6 md:p-8 space-y-3">
                  <p className="font-normal text-[16px] font-[archivo] tracking-wide text-color-semilight">
                    {hightlightDate}
                  </p>
                  <h1 className="font-normal font-[archivo] text-[32px] leading-[40px]">
                    {highlightBlog.title.rendered}
                  </h1>
                  <p className="text-color-semilight font-[300] text-[16px] leading-[25px] font-[archivo]">
                    <div dangerouslySetInnerHTML={{ __html: highlightBlog.excerpt.rendered }} className="blogHighlight"></div>
                  </p>
                </div>
              </div>
            </a>

          ) : (
            <div className="rounded-xl overflow-hidden">
              <div className="bg-[#0E0E0E] p-6 md:p-8 space-y-3">
                <p className="font-normal text-[16px] font-[archivo] tracking-wide text-color-semilight">
                  {hightlightDate}
                </p>
                <div className="font-normal font-[archivo] text-[32px] leading-[40px]">
                  {highlightBlog.title.rendered}
                </div>
                <p className="text-color-semilight font-[300] text-[16px] leading-[25px] font-[archivo]">
                  <div dangerouslySetInnerHTML={{ __html: highlightBlog.excerpt.rendered }} className="blogHighlight"></div>
                </p>
              </div>
            </div>
          )}
        </div>}
        <div className="w-full">
          <h3 className="font-semibold py-10 font-[archivo] text-[24px] leading-[32px]">
            {" "}
            All Blogs
          </h3>
          <div className="grid grid-cols-1 xs:grid-cols-1 md:grid-cols-2 lg2:grid-cols-3 gap-4 rounded-xl overflow-hidden pb-24">

            {blogs !== null && blogs?.map((blog, index) => (

              loaded ?
                <div key={index} className="cursor-pointer bg-white/10 rounded-xl" onClick={() => navigate(`/community/blog/${blog.id}/${blog.slug}`)}>
                  <img src={blog._embedded["wp:featuredmedia"] ? blog._embedded["wp:featuredmedia"][0].source_url : ""} alt="" className="w-full rounded-t-xl sm:min-h-[25rem] md:min-h-[20rem] md:max-h-[15rem] lg:min-h-[15rem] md:max-h-[15rem] overflow-hidden" />
                  <div className="p-6">
                    <h4 className="text-[24px] font-normal mb-2 leading-[32px] line-clamp-3">
                      {blog.title.rendered}
                    </h4>
                    <p className="text-color-semilight text-[16px] font-[archivo] font-normal leading-[23px] line-clamp-4">
                      {/* {blog.introduction} */}
                    </p>
                  </div>
                </div> : <div key={index} className="cursor-pointer bg-white/10 rounded-xl" onClick={() => navigate(`/community/blog/${blog.id}/${blog.slug}`)}>
                  <div className='w-full rounded-t-xl min-h-[15rem] max-h-[15rem] overflow-hidden'>

                    <Skeleton
                      width={300}
                      height={240}
                      baseColor={BASE_COLOR}
                      highlightColor={HIGHLIGHT_COLOR}
                    />
                  </div>
                  {/* <img src={blog.featured_image} alt="" className="w-full rounded-t-xl min-h-[15rem] max-h-[15rem]" /> */}
                  <div className="p-6">
                    <h4 className="text-[24px] font-normal mb-2 leading-[32px] line-clamp-3">
                      <Skeleton
                        width={200}
                        height={24}
                        baseColor={BASE_COLOR}
                        highlightColor={HIGHLIGHT_COLOR}
                      />
                    </h4>
                    <p className="text-color-semilight text-[16px] font-[archivo] font-normal leading-[23px] line-clamp-4">
                      {/* {blog.introduction} */}
                    </p>
                  </div>
                </div>

            ))}
          </div>
        </div>
      </div>
      <MetaPixel />
    </div>
  );
};
export default CommunityPage;
